document.addEventListener("DOMContentLoaded", runVideoPlayScripts);

function runVideoPlayScripts() {
//play button
    const play = document.querySelectorAll(' .fz-video-play');
    assignEvents(play, 'click', playVideo);
}

//Add an Event Listener to Multiple Elements
function assignEvents(elems, type, event) {
    for (let i = 0; i < elems.length; i++) {
        elems[i].addEventListener(type, event, false);
    }
}

function playVideo(e) {
    const box = e.currentTarget.parentElement,
        video = box.querySelector('video'),
        poster = box.querySelector('.video-poster');

    if (typeof (video) == 'undefined' || !video) return true;
    if (video.paused) {
        allPause();
        video.play();
        if(typeof(poster) != "undefined" && poster) {
            poster.classList.add('hide');
        }
        e.currentTarget.classList.add('active');
        video.addEventListener('ended', allPause);
    } else {
        video.pause();
        if(typeof(poster) != "undefined" && poster) {
            poster.classList.remove('hide');
        }
        e.currentTarget.classList.remove('active');
        if (status_el) {
            let status_el = e.currentTarget.querySelector('.fz-video-play__status'),
                status = status_el.getAttribute('data-show-play');
            status_el.textContent = status;
        }
    }
}

function allPause() {
    const allPlayButtons = document.querySelectorAll('.fz-video-play');
    for (let i = 0; i < allPlayButtons.length; i++) {

        const video = allPlayButtons[i].parentElement.querySelector('video'),
            poster = allPlayButtons[i].parentElement.querySelector('.video-poster');
        if (typeof (video) == 'undefined' || !video) continue;
        let container = video.parentElement,
            status_el = container.querySelector('.fz-video-play__status');
        if (status_el) {
            status_el.textContent = status_el.getAttribute('data-show-pause');
        }
        if(typeof(poster) != "undefined" && poster) {
            poster.classList.remove('hide');
        }
        video.pause();
    }
    for (let i = 0; i < allPlayButtons.length; i++) {
        allPlayButtons[i].classList.remove('active');
    }
}
